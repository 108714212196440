import $ from 'jquery';

$( document ).ready(function() {
    let upload = $('input[type=file]');
    if(upload?.attr('id') === "job_application_documents"){
        return;
    }
    if(upload.length > 0) {
        let origin = upload.prev('label').html();
        let name = '';
        upload.on('change', function() {
            if(this.files[0]) {
                name = $(this).val().split( '\\' ).pop();
                if(name) {
                    $(this).prev('label').html(name);
                } else {
                    $(this).prev('label').html(origin);
                }
            } else {
                $(this).prev('label').html(origin);
            }

        });
    }
});

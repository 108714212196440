require('./jquery');
require('./form');
require('./recaptcha');
require('./navigation');
require('./foundation');
require('./clickout');
require('./picturefill');
require('./slickslider');
require('./magnific-popup');
require('./video');
require('./products');
require('./product-list');
require('./selectbox');
require('./catalogues');
require('./masonry');
require('./sitesearch');
require('./product-compare');
require('./teaser');
require('./upload');
require('./swiper');
require('./shop-page');
require('./tax-class-switcher');

(function() {
    if (document.getElementById("merchant-map") === null) {
        return;
    } else {
        let googleMap = document.getElementById("merchant-map");
        googleMapPermission(googleMap);
    }
    function googleMapPermission(googleMap) {
        if (CCM && typeof (CCM.acceptedEmbeddings.find(element => element.name == 'Google Maps Karte')) !== 'undefined') {

            document.getElementById("googleMapsScript").addEventListener('load', function() {
                require('./gmaps');
            });
            document.getElementById("googleMapsScript").src = document.getElementById("googleMapsScript").dataset.src;
            googleMap.classList.remove('no-consent');
            require('./merchant-list');
        } else {
            googleMap.classList.add('no-consent');
            require('./merchant-list');
        }
        window.addEventListener('ccm19WidgetClosed', function(e) {
            googleMapPermission(googleMap);
        });
    }
})()

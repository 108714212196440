import { useSignal, useEffect } from '../../lib/reactivity';

document.addEventListener('DOMContentLoaded', function() {
    const containers = document.getElementsByClassName('tax-class-popup-container');

    if (!containers.length) {
        return;
    }
    const elements = Array.prototype.slice.call(containers);

    elements.map(function(container) {
        initializeTaxClassSwitcherPopup(container);
    });

});


function initializeTaxClassSwitcherPopup(container) {



    const [isVisible, setVisible] = useSignal(container.dataset?.show === 'true');
    const [country, setCountry] = useSignal(null);
    const [loading, setLoading] = useSignal(false);
    const [message, setMessage] = useSignal('');

    const triggers = document.querySelectorAll('.tax-class-switcher');
    triggers.forEach(trigger => {
        trigger.addEventListener('click', () => setVisible(!isVisible()));
    });

    const selection = container.querySelector('#taxClassPopupCountry');
    const closeButton = container.querySelectorAll('.close-popup');
    const backdrop = container.querySelector('.tax-class-backdrop');
    const submitButton = container.querySelector('.tax-class-popup-body button.primary');
    const loader = submitButton?.querySelector('.icon');

    closeButton.forEach((btn) => {
        btn.addEventListener('click', () => setVisible(!isVisible()));
    });

    backdrop?.addEventListener('click', (e) => {
        if (e.target !== backdrop) {
            return;
        }
        setVisible(!isVisible());
    });

    submitButton?.addEventListener('click', function() {
        setCountry(selection.value);
        setLoading(true);
        const url = `/api/shop/country?country=${country()}&locale=${document.documentElement.lang}`;
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.success) {
                    window.location.reload();
                } else {
                    setMessage(data.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    });

    useEffect(() => {
        if (isVisible()) {
            container.classList.add('show');
        } else {
            container.classList.remove('show');
        }
    });

    useEffect(() => {
        if (loading()) {
            submitButton?.setAttribute('disabled', 'disabled');
            loader?.classList.add('loading-animation');
        } else {
            submitButton?.removeAttribute('disabled');
            loader?.classList.remove('loading-animation');
        }
    });

    useEffect(() => {
        const messageContainer = container.querySelector('.tax-class-popup-message');
        if (message()) {
            messageContainer.innerHTML = message();
        } else {
            messageContainer.innerHTML = '';
        }
    })
}

